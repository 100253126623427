import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import Partners from "./partners";
import { Wrapper } from "./styled-index";

function HamburgerMenu({ HamburgerClick, HandleClickClose }) {
  const { t, i18n } = useTranslation();
  const [drop, setDrop] = useState(false);
  const handleLang = (e) => {
    const lang = e.target.value;
    i18n.changeLanguage(lang);
    window.location.reload();
  };
  const navigate = useNavigate();
  function LanguValue() {
    return window.localStorage.getItem("i18nextLng");
  }

  const data = [
    {
      id: 1,
      title: "Header.0",
      link: "/",
    },
    {
      id: 4,
      title: "Header.1",
      link: "/blog",
    },
    {
      id: 5,
      title: "Header.2",
      link: "/about",
    },
    {
      id: 5,
      title: "Header.3",
      link: "/client",
    },
    {
      id: 5,
      title: "Footer.5",
      link: "/uslugy",
    },
    {
      id: 6,
      title: "Header.4",
      link: "/contact",
    },
  ];
  return (
    <>
      {HamburgerClick === true ? (
        <Wrapper style={{ left: "0px" }}>

          <span onClick={HandleClickClose}>&times;</span>
          <select onChange={handleLang}>
            {LanguValue() === "ru" ? (
              <>
                <option value="ru">RUS</option>
                <option value="uz">UZB</option>
                <option value="en">ENG</option>
              </>
            ) : LanguValue() === "uz" ? (
              <>
                <option value="uz">UZB</option>
                <option value="ru">RUS</option>
                <option value="en">ENG</option>
              </>
            ) : LanguValue() === "en" ? (
              <>
                <option value="en">ENG</option>
                <option value="uz">UZB</option>
                <option value="ru">RUS</option>
              </>
            ) : (
              <>
                <option value="ru">RUS</option>
                <option value="en">ENG</option>
                <option value="uz">UZB</option>
              </>
            )}
          </select>
          <ul>
              <>

              <Partners  HandleClickClose={HandleClickClose}/>
                <hr />
              </>
                        {data.slice(1,2).map((elem) => (
              <>
                <li key={elem.id}>
                  <button>
                    <p  onClick={ () =>{
                       navigate("/blog")
                      window.location.reload();
}} to={elem.link}>
                      {t(elem.title)}
                    </p>
                  </button>
                </li>
                <hr />
              </>
            ))}
                                    {data.slice(2).map((elem) => (
              <>
                <li key={elem.id}>
                  <button>
                    <NavLink onClick={HandleClickClose} to={elem.link}>
                      {t(elem.title)}
                    </NavLink>
                  </button>
                </li>
                <hr />
              </>
            ))}
          </ul>
          <a href="tel:+998998376500" className="number">+99899 (837)-65-00</a>

        </Wrapper>
      ) : HamburgerClick === false ? (
        <Wrapper style={{ left: "-650px" }}>
          <span onClick={HandleClickClose}>&times;</span>
          <select onChange={handleLang}>
            {LanguValue() === "ru" ? (
              <>
                <option value="ru">RUS</option>
                <option value="uz">UZB</option>
                <option value="en">ENG</option>
              </>
            ) : LanguValue() === "uz" ? (
              <>
                <option value="uz">UZB</option>
                <option value="ru">RUS</option>
                <option value="en">ENG</option>
              </>
            ) : LanguValue() === "en" ? (
              <>
                <option value="en">ENG</option>
                <option value="uz">UZB</option>
                <option value="ru">RUS</option>
              </>
            ) : null}
          </select>
          <ul>
            {data.map((elem) => (
              <>
                <li key={elem.id}>
                  <NavLink to={elem.link}>{t(elem.title)}</NavLink>
                </li>
                <hr />
              </>
            ))}
          </ul>
        </Wrapper>
      ) : null}
    </>
  );
}
export default HamburgerMenu;
