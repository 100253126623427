import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const GetTopProducts = createAsyncThunk("TopProducts/get", async () => {
  return await axios.get(`${API_URL}/top`).then((response) => response.data);
});
export const GetTopProductsId = createAsyncThunk("productsId/get", async (id) => {
  return await axios.get(`${API_URL}/top/${id}`).then((response) => response.data);
});
const TopProductsSlice = createSlice({
  name: "TopProducts",
  initialState: {
    getTopProducts: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    getTopProductsid: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    }
  },
  extraReducers: {
    [GetTopProducts.pending]: (state, action) => {
      state.getTopProducts.loading = true;
    },
    [GetTopProducts.fulfilled]: (state, action) => {
      state.getTopProducts.Error = false;
      state.getTopProducts.Success = false;
      state.getTopProducts.Loading = false;
      state.getTopProducts.Data = action.payload;
    },
    [GetTopProducts.rejected]: (state, action) => {
      state.getTopProducts.Error = true;
      state.getTopProducts.Success = false;
      state.getTopProducts.Loading = false;
      state.getTopProducts.Data = [];
    },
    [GetTopProductsId.pending]: (state, action) => {
      state.getTopProductsid.loading = true;
    },
    [GetTopProductsId.fulfilled]: (state, action) => {
      state.getTopProductsid.Error = false;
      state.getTopProductsid.Success = false;
      state.getTopProductsid.Loading = false;
      state.getTopProductsid.Data = action.payload;
    },
    [GetTopProductsId.rejected]: (state, action) => {
      state.getTopProductsid.Error = true;
      state.getTopProductsid.Success = false;
      state.getTopProductsid.Loading = false;
      state.getTopProductsid.Data = [];
    },
  },
});

export const {} = TopProductsSlice.actions;
export default TopProductsSlice.reducer;
