import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const Products = createAsyncThunk("Products/get", async () => {
  return await axios.get(`${API_URL}/products`).then((response) => response.data);
});
export const ProductsId = createAsyncThunk("ProductsId/get", async (id) => {
  return await axios.get(`${API_URL}/products/${id}`).then((response) => response.data);
});
const ProductsSlice = createSlice({
  name: "Products",
  initialState: {
    Products: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    Productsid: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    }
  },
  extraReducers: {
    [Products.pending]: (state, action) => {
      state.Products.loading = true;
    },
    [Products.fulfilled]: (state, action) => {
      state.Products.Error = false;
      state.Products.Success = false;
      state.Products.Loading = false;
      state.Products.Data = action.payload;
    },
    [Products.rejected]: (state, action) => {
      state.Products.Error = true;
      state.Products.Success = false;
      state.Products.Loading = false;
      state.Products.Data = [];
    },
    [ProductsId.pending]: (state, action) => {
      state.Productsid.loading = true;
    },
    [ProductsId.fulfilled]: (state, action) => {
      state.Productsid.Error = false;
      state.Productsid.Success = false;
      state.Productsid.Loading = false;
      state.Productsid.Data = action.payload;
    },
    [ProductsId.rejected]: (state, action) => {
      state.Productsid.Error = true;
      state.Productsid.Success = false;
      state.Productsid.Loading = false;
      state.Productsid.Data = [];
    },
  },
});

export const {} = ProductsSlice.actions;
export default ProductsSlice.reducer;
