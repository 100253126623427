import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const Blog = createAsyncThunk("Blog/get", async () => {
  return await axios.get(`${API_URL}/blog`).then((response) => response.data);
});
export const BlogId = createAsyncThunk("BlogId/get", async (id) => {
  return await axios.get(`${API_URL}/blog/${id}`).then((response) => response.data);
});
const BlogSlice = createSlice({
  name: "Blog",
  initialState: {
    Blog: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    Blogid: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    }
  },
  extraReducers: {
    [Blog.pending]: (state, action) => {
      state.Blog.loading = true;
    },
    [Blog.fulfilled]: (state, action) => {
      state.Blog.Error = false;
      state.Blog.Success = false;
      state.Blog.Loading = false;
      state.Blog.Data = action.payload;
    },
    [Blog.rejected]: (state, action) => {
      state.Blog.Error = true;
      state.Blog.Success = false;
      state.Blog.Loading = false;
      state.Blog.Data = [];
    },
    [BlogId.pending]: (state, action) => {
      state.Blogid.loading = true;
    },
    [BlogId.fulfilled]: (state, action) => {
      state.Blogid.Error = false;
      state.Blogid.Success = false;
      state.Blogid.Loading = false;
      state.Blogid.Data = action.payload;
    },
    [BlogId.rejected]: (state, action) => {
      state.Blogid.Error = true;
      state.Blogid.Success = false;
      state.Blogid.Loading = false;
      state.Blogid.Data = [];
    },
  },
});

export const {} = BlogSlice.actions;
export default BlogSlice.reducer;
