import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const Catalog = createAsyncThunk("Catalog/get", async () => {
  return await axios.get(`${API_URL}/category`).then((response) => response.data);
});
export const CatalogId = createAsyncThunk("CatalogId/get", async (id) => {
  return await axios.get(`${API_URL}/category/${id}`).then((response) => response.data);
});
const CatalogSlice = createSlice({
  name: "Catalog",
  initialState: {
    Catalog: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    },
    Catalogid: {
      Error: false,
      Loading: false,
      Success: false,
      Data: [],
    }
  },
  extraReducers: {
    [Catalog.pending]: (state, action) => {
      state.Catalog.loading = true;
    },
    [Catalog.fulfilled]: (state, action) => {
      state.Catalog.Error = false;
      state.Catalog.Success = false;
      state.Catalog.Loading = false;
      state.Catalog.Data = action.payload;
    },
    [Catalog.rejected]: (state, action) => {
      state.Catalog.Error = true;
      state.Catalog.Success = false;
      state.Catalog.Loading = false;
      state.Catalog.Data = [];
    },
    [CatalogId.pending]: (state, action) => {
      state.Catalogid.loading = true;
    },
    [CatalogId.fulfilled]: (state, action) => {
      state.Catalogid.Error = false;
      state.Catalogid.Success = false;
      state.Catalogid.Loading = false;
      state.Catalogid.Data = action.payload;
    },
    [CatalogId.rejected]: (state, action) => {
      state.Catalogid.Error = true;
      state.Catalogid.Success = false;
      state.Catalogid.Loading = false;
      state.Catalogid.Data = [];
    },
  },
});

export const {} = CatalogSlice.actions;
export default CatalogSlice.reducer;
