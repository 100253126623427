import React, { useEffect } from "react";
import styles from "./style.module.css";
import FooterLogo from "./../../assets/home/footer-logo.svg";
import { Row, Col } from "react-grid-system";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Catalog } from "../../redux/catalog";
const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
        dispatch(Catalog());
  }, [])
  const CatalogGet = useSelector(state => state.Catalog.Catalog.Data)
  const navigate = useNavigate();
  const HandleClickMore =   (e) => {
    navigate(`/products/${e.target.id}`);
    window.localStorage.setItem("CategoryId" , e.target.id)
 };
  return (
    <div className={styles.WrapperBody}>
      <div className={styles.Container}>
        <div className={styles.Wrapper}>
          <Row>
            <Col lg={3} md={12}>
              <img src={FooterLogo} width={227} height={44} alt="" />
              <p className={styles.Paragraph}>© 2019 PRO Auto. Все права защищены.</p>
            </Col>
            <Col lg={2} md={12}>
              <ul>
                <li>
                  <NavLink className={styles.link} to={"/"}>
                    <p className={styles.FooterLinkHead}>{t("Footer.8")}</p>
                  </NavLink>
                </li>
                <li onClick={() => {
                       navigate("/blog")
                      window.location.reload();
}}>
                    <p className={styles.FooterLink}>{t("Footer.0")}</p>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/about"}>
                    <p className={styles.FooterLink}>{t("Footer.1")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/client"}>
                    <p className={styles.FooterLink}>{t("Footer.2")}</p>
                  </NavLink>
                </li>
                
                <li>
                  <NavLink className={styles.link} to={"/contact"}>
                    <p className={styles.FooterLink}>{t("Footer.3")}</p>
                  </NavLink>
                </li>

              </ul>
            </Col>
            <Col lg={2} md={12}>
              <ul>
                <li>
                  <NavLink className={styles.link} to={"/"}>
                    <p className={styles.FooterLinkHead}>{t("Footer.4")}</p>
                  </NavLink>
                </li>
                    {CatalogGet.map((elem , index) => 
                                                        <li key={index} onClick={HandleClickMore} id={elem.id} >
                                                        <NavLink className={styles.link} id={elem.id} >
                                                          <p className={styles.FooterLink} id={elem.id}>{elem.title_ru}</p>
                                                        </NavLink>
                                                      </li>
                    )}
              </ul>
            </Col> 
            <Col lg={2} md={12}>
              <ul>
                <li>
                  <NavLink className={styles.link} to={"/uslugy"}>
                    <p className={styles.FooterLinkHead}>{t("Footer.5")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/uslugy"}>
                    <p className={styles.FooterLink}>{t("Footer.6")}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.link} to={"/uslugy"}>
                    <p className={styles.FooterLink}>{t("Footer.7")}</p>
                  </NavLink>
                </li>
              </ul>
            </Col> 
            <Col lg={3} md={12}>
              <ul>
                <li>
                    <a href="tel:+ +998974222222" className={styles.FooterLinkHead}><i class='bx bx-phone'  ></i>  +99897 (422)-22-22</a>
                </li>
                <li>
                    <a href="mail:tireuzb@mail.ru" className={styles.FooterLinkHead}><i class='bx bx-comment-dots' ></i> tireuzb@mail.ru</a>
                </li>
                <button>{t("Home.1")}</button>
              </ul>
            </Col>
          </Row>

        </div>
        <a href="https://supersiteuz.com/" className={styles.linkCompany}>
©Разработала команда Supersite.uz</a>
      </div>
      
    </div>
  );
};

export default Footer;
