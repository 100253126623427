import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../utils/api";

export const PostContact = createAsyncThunk("PostContact/post",  (body) => {
  return axios.post(`${API_URL}/form` , body).then(res => res.data)
});

const PostContactSlice = createSlice({
  name: "PostContact",
  initialState: {
    PostContact: {
      Error: false,
      Loading: false,
      Success: false,
    },

  },
  extraReducers: {
    [PostContact.pending]: (state, action) => {
      state.PostContact.loading = true;
    },
    [PostContact.fulfilled]: (state, action) => {
      state.PostContact.Error = false;
      state.PostContact.Success = true;
      state.PostContact.Loading = false;
    },
    [PostContact.rejected]: (state, action) => {
      state.PostContact.Error = true;
      state.PostContact.Success = false;
      state.PostContact.Loading = false;
    },
  },
});

export const {} = PostContactSlice.actions;
export default PostContactSlice.reducer;
