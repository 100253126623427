import { configureStore } from "@reduxjs/toolkit";
import TopProductsSlice from "./top_products/index";
import ProductsSlice from "./products/index"
import PostContactSlice from "./concat/index";
import CatalogSlice from "./catalog/index";
import BlogSlice  from "./blog/index"
export const store = configureStore({
  reducer: {
    TopProducts: TopProductsSlice,
    Contact : PostContactSlice,
    Catalog : CatalogSlice,
    Products : ProductsSlice,
    Blog : BlogSlice
  },
});
