import './App.css';
import Header from './components/Header';
import RouterComponent from './router';
function App() {
  return (
    <>
    <Header/>
    <RouterComponent/>
    </>
  );
}

export default App;
