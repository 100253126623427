import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styles from "./style.module.css"
const NotFound = () => {
    const {t} = useTranslation()
  return (
    <div className={styles.Wrapper}>
        <div className={styles.Container}>
            <h1>404</h1>
            <h2>{t("NotFound.0")}</h2>
            <p>{t("NotFound.1")}</p>
            <button><NavLink className={styles.link} to="/">{t("NotFound.2")}</NavLink></button>
        </div>
    </div>
  )
}

export default NotFound