import { lazy } from "react";
import NotFound from "../pages/NotFound";

const Home = lazy(() => import("./../pages/Home/index"))
const About = lazy(() => import("./../pages/About/index"))
const More = lazy(() => import("./../pages/more/index"))
const ContactPage = lazy(() => import("./../pages/Contact/index"))
const Uslugy = lazy(() => import("./../pages/uslugy/index"))
const Blog = lazy(() => import("./../pages/blog/index")) 
const Products = lazy(() => import("./../pages/products/index"))
const UslugyPage = lazy(() => import("./../pages/uslugy-page/index"))
export const RouterData = [
    {
        id : 1,
        path : "/products/:id",
        component : <Products/>
    },
    {
        id : 1,
        path : "/",
        component : <Home/>
    },
    {
        id : 1,
        path : "/about",
        component : <About/>
    },
    {
        id : 1,
        path : "/contact",
        component : <ContactPage/>
    },
    {
        id : 1,
        path : "/more/:id",
        component : <More/>
    },
    {
        id : 1,
        path : "/client",
        component : <Uslugy/>
    },
    {
        id : 1,
        path : "/blog",
        component : <Blog/>
    },
    {
        id : 1,
        path : "/uslugy",
        component : <UslugyPage/>
    },
    {
        id : 2,
        path: "/*",
        component: <NotFound/>
    }
]